<template>
    <div>
        <v-card
            class="pa-2 mt-4"
            color="#212121"
            elevation="4"
            dark
            v-if="tehilimData.book.length > 0 && tehilimData.selected.length > 0">

            <div class="text-center primary--text font-weight-medium mt-2">
                שיתוף {{ title }}
            </div>

            <v-checkbox
                class="mt-2"
                dense
                v-model="dedicatedCheckbox"
                label="הוספת הקדשה">
            </v-checkbox>

            <div v-if="dedicatedCheckbox">
                <div class="text-center caption primary--text font-weight-bold">
                    להוספת הקדשה מלאו את הפרטים הבאים
                </div>

                <v-select
                    class="mt-4"
                    :items="items"
                    label="מוקדש ל"
                    color="primary"
                    v-model="selected"
                    dense
                    hide-details
                    outlined>
                </v-select>

                <v-text-field
                    class="mt-2"
                    label="פלוני/ת בן/ת פלוני/ת"
                    v-model="name"
                    outlined
                    dense
                    hide-details
                    color="primary">
                </v-text-field>

                <v-btn
                    class="primary--text mt-2 mx-auto mb-3"
                    color="white"
                    dark
                    :disabled="(selected.length && name.length) ? false : true"
                    @click="createLink"
                    block>
                    הוספת הקדשה
                </v-btn>
            </div>


             <v-divider class="mx-auto"></v-divider>
                <v-text-field
                    label="לינק"
                    color="primary"
                    class="mt-4 white--text"
                    background-color="black"
                    filled
                    dense
                    dark
                    outlined
                    hide-details="true"
                    v-model="share.url"
                    prepend-inner-icon="mdi-link-variant"
                    readonly>
                </v-text-field>

                <v-btn
                    class="mt-4 white--text"
                    color="primary"
                    @click="copyToClipboard"
                    block>
                    <v-icon left>
                        mdi-content-copy
                    </v-icon>
                    העתקת לינק
                </v-btn>
                <div class="mt-4 text-center caption">לשיתוף  
                    <strong class="primary--text">{{ title }}</strong>
                </div>
                <div class="text-center caption" v-if="share.description.length">  
                    <strong class="primary--text">ה{{ share.description }}</strong>
                </div>
                
                <div>
                   <ShareDedicated :shareData="share"/>
                </div>


        </v-card> 
        <v-snackbar
            v-model="snackbar"
            content-class="mx-auto text-center"
            width="1"
            color="primary"
            timeout="2000">
            <v-icon right>
                mdi-check-circle 
            </v-icon>
            {{ snackbarTxt }}
        </v-snackbar>
    </div>
</template>


<script>
import ShareDedicated from './App/ShareDedicated.vue'

export default {
    props: ['tehilimData'],
    data() {
        return {
            dedicatedCheckbox: false,
            share: {
                title: '',
                description: '',
                url: ''
            },
            url: '',
            items: [
                'עילוי נשמת',
                'רפואת',
                'הצלחת'
            ],
            selected: '',
            name: '',
            snackbar: false,
            snackbarTxt: 'לינק הועתק!'
        }
    },
    computed: {
        title: function() {
            let selected =  this.tehilimData.selected
            let titleTxt = ''
            switch(selected) {
                case 'ספר':
                    titleTxt = 'תהילים ' + this.tehilimData.book
                    break
                case 'יום':
                    titleTxt = 'תהילים ל' + this.tehilimData.book
                    break
                case 'פרק':
                    titleTxt = 'תהילים פרק ' + this.tehilimData.book
                    break
                case 'תאריך':
                    titleTxt = 'תהילים ליום ' + this.tehilimData.book + ' לחודש'
                    break
                default:
                    titleTxt = ''
                    break
            }
            return titleTxt
        }
    },
    methods: {
        copyToClipboard() {
            this.snackbarTxt = 'לינק הועתק!'
            navigator.clipboard.writeText(this.share.url)
            this.snackbar = true
        },
        createLink() {
            this.snackbar = false
            this.snackbarTxt = "הקדשה חדשה נוצרה!"
            let type = this.getTypeNumber()
            let nameTo = this.name.replaceAll(' ', '%20')
            this.share.title = this.title
            this.share.description = 'מוקדש ל' + this.selected + ' ' + this.name
            this.share.url = this.url + '/dedicated/' + type + '/to/' + nameTo
            this.snackbar = true
        },
        getTypeNumber() {
            switch(this.selected) {
                case 'עילוי נשמת':
                    return '1'
                case 'רפואת':
                    return '2'
                case 'הצלחת':
                    return '3'
                default:
                    return ''
            }
        },
    },
    watch: {
        dedicatedCheckbox: function(value) {
            if(!value) {
                this.share.title = this.title
                this.share.description = ''
                this.share.url = this.url
                this.name = ''
                this.selected = ''
            }
        },
        tehilimData: function(data) {
            if(data.book.length > 0 && data.selected.length > 0) {
                this.dedicatedCheckbox = false
                this.url = ("https://AmIsraeLive.com/tehilim/select/" + data.selected + '/book/' + data.book).replaceAll(' ', '%20')
                this.share.title = this.title
            }
        },
        url: function(data) {
            this.share.url = data.replaceAll(' ', '%20')
        }
    },
    components: {
        ShareDedicated
    },
    created() {
        this.url = ("https://AmIsraeLive.com/tehilim/select/" + this.tehilimData.selected + '/book/' + this.tehilimData.book).replaceAll(' ', '%20')
        this.share.title = this.title
    }
}

</script>